@import '../../vars.scss';

.End {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;

    video {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        object-fit: cover;
        z-index: 1;
    }

    h3 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        font-size: 3rem;
        letter-spacing: 1rem;
    }

    .Credits {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70%;
        height: 100%;
        margin: 0 auto;
        font-size: 14px;

        p {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin-bottom: 20px;
            font-size: 15px;

            span {
                flex: 0 0 50%;
                padding-right: 10px;
                text-align: right;
            }
            strong {
                flex: 0 0 50%;
                padding-left: 10px;
                text-align: left;
            }

            &:last-child {
                font-style: italic;
                padding-top: 20px;
            }
        }
    }
}