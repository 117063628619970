@import "./vars.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html {
    margin: 0;
    padding: 0;
    font-size: 11px;
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    font-family: $font-base;
    background-color: #000;
    @include from-laptop {
        height: 100vh;
    }
}

#root {
    display: flex;
    width: 100%;
    height: 100%;
}

.hidden {
    display: none !important;
}

.App {
    background-color: #000;
    color: #fff;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    article {
        width: 100%;
        flex: 1;
        text-align: center;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        z-index: 1;
    }

    > div {
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;

        article {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
}

.App-component {
    display: block;
    width: 100%;
    height: 100%;
}

.icon {
    display: inline-block;
    width: 25px;
    height: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &.icon-headphone {
        background-image: url('./assets/icon-headphone.svg');
        animation: pulseOpacity 2s infinite;
    }
}

.fade-enter {
    opacity: 0;
}
  
.fade-enter-active {
    opacity: 1;
    transition: all 900ms;
}
  
.fade-exit {
    opacity: 1;
    transition: all 900ms;
}
  
.fade-exit-active {
    opacity: 0;
}
