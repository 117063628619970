@import '../../vars.scss';

.VideoPlayer {
    display: block;
    width: 100%;
    @include from-laptop {
        width: 100%;
        margin: 0 auto;
    }

    .VideoPlayer-player-container {
        display: block;
        width: 100%;
        height: 100vh;
        position: relative;

        // &:after {
        //     content: '';
        //     display: block;
        //     padding-bottom: 56.25%;
        // }
    }

    .video-js {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    canvas {
        left: 0;
    }

    video {
        &:focus {
            outline: none;
        }
    }
}

.VideoPlayer-title {
    color: #fff;
    margin-bottom: 10px;
    font-size: 1.7rem;
    font-family: $font-base;
}