@import "../../vars.scss";

.Map-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .Map-content {
        transform: scale(0.7);
        @include for-mobile {
            transform: scale(1.2);
            padding-left: 10vw;
        }
    }

    &.Map-container--animated {
        .Map-block {
            opacity: 0;
        }

        @for $i from 1 through 20 {
            .Map-block-#{$i} {
                animation: fadeIn 900ms forwards;
                animation-delay: #{$i*100}ms;
            }
        }
    }

    .Map {
        display: block;
        width: 100%;
        height: auto;
        transform: scale(0.9);

        .Map-line {
            display: flex;
            position: relative;
            justify-content: center;

            &.Map-line--2 {
                margin-top: -22vw;
            }

            &.Map-line--3 {
                margin-top: -5vw;
            }
        }


        .Map-block {
            appearance: none;
            border: none;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20vw;
            height: 10vw;
            cursor: pointer;
            z-index: 2;
            padding: 0;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                opacity: 1;
                transition: opacity 400ms;
            }

            &:after {
                content: '';
                display: block;
                width: 25px;
                height: 25px;
                background-image: url('../../assets/icon-play-hover.svg');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                opacity: 0;
                transition: opacity 400ms;
                @include for-mobile {
                    width: 14px;
                    height: 14px;
                }
            }

            &:focus {
                outline: none;
            }

            span {
                position: absolute;
                display: none;
            }

            &:hover {
                &:before {
                    opacity: 0;
                }
                &:after {
                    opacity: 1;
                }
            }

            &.completed {
                &:before {
                    opacity: 0;
                }
            }
            
            &.Map-block-1 {
                &:before {
                    background-image: url('../../assets/map/map1.svg');
                }
                position: relative;
                width: 17vw;
                height: 15vw;
                margin-right: 1vw;
                background-image: url('../../assets/map/pink/map1.svg');
                // &.completed {
                //     background-image: url('../../assets/map/map1.png');
                // }
            }
            &.Map-block-2 {
                &:before {
                    background-image: url('../../assets/map/map2.svg');
                }
                position: relative;
                width: 18vw;
                background-image: url('../../assets/map/pink/map2.svg');
                // &.completed {
                //     background-image: url('../../assets/map/map2.png');
                // }
            }
            &.Map-block-3 {
                &:before {
                    background-image: url('../../assets/map/map3.svg');
                }
                position: relative;
                width: 20vw;
                height: 17.5vw;
                margin-left: -1.2vw;
                background-image: url('../../assets/map/pink/map3.svg');
                // &.completed {
                //     background-image: url('../../assets/map/map3.png');
                // }
            }
            &.Map-block-4 {
                &:before {
                    background-image: url('../../assets/map/map4.svg');
                }
                position: relative;
                height: 28vw;
                margin-left: -36.3vw;
                margin-top: -5vw;
                background-image: url('../../assets/map/pink/map4.svg');
            }
            &.Map-block-5 {
                &:before {
                    background-image: url('../../assets/map/map5.svg');
                }
                position: relative;
                width: 12vw;
                height: 11vw;
                margin-top: -3.8vw;
                background-image: url('../../assets/map/pink/map5.svg');
            }
            &.Map-block-6 {
                &:before {
                    background-image: url('../../assets/map/map6.svg');
                }
                position: relative;
                width: 10vw;
                margin-right: 1.3vw;
                margin-left: 1.3vw;
                margin-top: -6.5vw;
                height: 6.5vw;
                background-image: url('../../assets/map/pink/map6.svg');
            }
            &.Map-block-7 {
                &:before {
                    background-image: url('../../assets/map/map7.svg');
                }
                position: relative;
                width: 9vw;
                height: 6.5vw;
                margin-top: -6.5vw;
                background-image: url('../../assets/map/pink/map7.svg');
            }
            &.Map-block-8 {
                &:before {
                    background-image: url('../../assets/map/map8.svg');
                }
                position: relative;
                width: 13vw;
                height: 12vw;
                margin-left: 15.3vw;
                background-image: url('../../assets/map/pink/map8.svg');
            }
            &.Map-block-9 {
                &:before {
                    background-image: url('../../assets/map/map9.svg');
                }
                position: relative;
                width: 9vw;
                height: 5.3vw;
                margin-left: -9.4vw;
                background-image: url('../../assets/map/pink/map9.svg');
            }
            &.Map-block-10 {
                &:before {
                    background-image: url('../../assets/map/map10.svg');
                }
                position: relative;
                height: 7.8vw;
                width: 8.8vw;
                margin-left: 1.4vw;
                background-image: url('../../assets/map/pink/map10.svg');
            }
            &.Map-block-11 {
                &:before {
                    background-image: url('../../assets/map/map11.svg');
                }
                position: relative;
                width: 8.4vw;
                height: 10vw;
                margin-left: -1.4vw;
                background-image: url('../../assets/map/pink/map11.svg');
            }
            &.Map-block-12 {
                &:before {
                    background-image: url('../../assets/map/map12.svg');
                }
                position: relative;
                width: 10vw;
                height: 11.4vw;
                margin-left: -1.2vw;
                background-image: url('../../assets/map/pink/map12.svg');
            }
            &.Map-block-13 {
                &:before {
                    background-image: url('../../assets/map/map13.svg');
                }
                position: relative;
                height: 12vw;
                width: 15vw;
                margin-left: 1vw;
                background-image: url('../../assets/map/pink/map13.svg');
            }
            &.Map-block-14 {
                &:before {
                    background-image: url('../../assets/map/map14.svg');
                }
                position: relative;
                height: 12.2vw;
                margin-top: 2.3vw;
                width: 14.3vw;
                margin-left: -4vw;
                background-image: url('../../assets/map/pink/map14.svg');
            }
            &.Map-block-15 {
                &:before {
                    background-image: url('../../assets/map/map15.svg');
                }
                position: relative;
                width: 8.5vw;
                height: 8vw;
                margin-top: -0.6vw;
                position: absolute;
                margin-left: 13.7vw;
                background-image: url('../../assets/map/pink/map15.svg');
            }
            &.Map-block-16 {
                &:before {
                    background-image: url('../../assets/map/map16.svg');
                }
                position: relative;
                width: 12vw;
                margin-top: 7.2vw;
                height: 7.1vw;
                background-image: url('../../assets/map/pink/map16.svg');
            }
            &.Map-block-17 {
                &:before {
                    background-image: url('../../assets/map/map17.svg');
                }
                position: relative;
                width: 18vw;
                height: 15vw;
                margin-top: 4vw;
                margin-left: 1vw;
                background-image: url('../../assets/map/pink/map17.svg');
            }
            &.Map-block-18 {
                &:before {
                    background-image: url('../../assets/map/map18.svg');
                }
                position: relative;
                width: 28.5vw;
                height: 14vw;
                margin-left: -22.3vw;
                margin-top: -10.8vw;
                background-image: url('../../assets/map/pink/map18.svg');
            }
            &.Map-block-19 {
                &:before {
                    background-image: url('../../assets/map/map19.svg');
                }
                position: relative;
                width: 9vw;
                margin-top: -5.7vw;
                background-image: url('../../assets/map/pink/map19.svg');
            }
            &.Map-block-20 {
                &:before {
                    background-image: url('../../assets/map/map20.svg');
                }
                position: relative;
                height: 16vw;
                margin-top: -8vw;
                width: 14vw;
                margin-left: 1vw;
                background-image: url('../../assets/map/pink/map20.svg');
            }
        }
    }

    .Slider {
        display: block;
        width: 80%;
        height: 80vh;
        margin: 0 auto;

        .slick-slider,
        .slick-list,
        .slick-track {
            height: 100%;

            div {
                height: 100%;
            }
        }

        .slick-slide {
            padding: 0 10px;
        }

        .Map-block {
            width: 100%;
            height: 100% !important;
            margin: 0 auto !important;
            &:after {
                width: 40px !important;
                height: 40px !important;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                opacity: 1 !important;
            }
        }
    }
}