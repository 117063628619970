@import "../../vars.scss";

.vjs-rb {
    .vjs-menu li {
        text-transform: none !important;

        .vjs-menu-item-text {
            @include for-smartphone {
                display: none;
            }
        }
    }
    .vjs-button:focus {
        outline: 0;
    }

    // bar
    .vjs-control-bar {
        background-color: transparent !important;
        height: 4em !important;
        align-items: center;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 100%);
        bottom: -1px !important;
    }

    // play and mute button
    .vjs-play-control .vjs-icon-placeholder:before,
    .vjs-mute-control .vjs-icon-placeholder:before {
        display: flex;
        align-items: center;
        justify-content: center;
    }


    // use big play button by default
    .vjs-big-play-button {
        pointer-events: none;
        background: none !important;
        border: none !important;
        font-size: 8rem !important;
    }
    &.vjs-rb-vr {
        .vjs-big-play-button {
            pointer-events: all;
        }
    }

    &.vjs-big-play-centered {
        &.vjs-playing.vjs-user-active .vjs-big-play-button {
            .vjs-icon-placeholder:before {
                content: "\F103";
                font-family: 'VideoJs';
            }
        }
        &.vjs-paused {
            .vjs-big-play-button {
                display: block !important;
            }
        }
        &.vjs-paused.vjs-user-active .vjs-big-play-button {
            .vjs-icon-placeholder:before {
                content: "\F101";
                font-family: 'VideoJs';
            }
        }
        &.vjs-user-inactive {
            .vjs-big-play-button {
                display: none !important;
            }
            &.vjs-has-started .vjs-big-play-button {
                display: block !important;
            }
            &.vjs-playing .vjs-big-play-button {
                display: none !important;
            }
        }
        &.vjs-user-active {
            .vjs-big-play-button {
                display: block !important;
            }
        }
    }

    &.vjs-rb-vr {
        &.vjs-big-play-centered {
            &.vjs-user-inactive {
                .vjs-big-play-button { 
                    display: none !important;
                }
            }
            &.vjs-user-active.vjs-playing {
                .vjs-big-play-button {
                    display: none !important;
                }
            }
        }
    }

    // volume control
    .vjs-volume-control {
        display: flex;
        height: 100%;
        align-items: center;
    }

    // timeline 
    .vjs-progress-control {
        position: absolute !important;
        left: 0;
        right: 110px;
        top: -2em;
        height: 2em !important;
        z-index: 1;
        width: calc(100% - 50px) !important;
    }
    .vjs-slider {
        background-color: rgba(255,255,255,0.2) !important;
    }
    .vjs-play-progress {
        background-color: $color-pink !important;
        
        &:before {
            color: $color-pink !important;
        }
    }
    .vjs-load-progress div {
        background-color: rgba(255,255,255,0.2) !important;
    }

    // time remaining
    .vjs-remaining-time {
        position: absolute !important;
        right: 0;
        top: -2em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 2em !important;
    }
    .vjs-time-divider {
        display: none;
    }

    // audio button
    .vjs-audio-button {
        .vjs-icon-placeholder:before {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .vjs-volume-panel {
        align-items: center;
    }

    // fullscreen button
    .vjs-fullscreen-control.vjs-control {
        position: absolute !important;
        right: 0;
        top: 0;

        .vjs-icon-placeholder:before {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    // pip button
    .vjs-picture-in-picture-control {
        display: none;
    }

    // remaining time
    .vjs-time-control {
        span {
            display: none;

            &.vjs-remaining-time-display {
                display: inline-block;
            }
        }
    }

    // popup menu
    .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
        z-index: 1;

        li {
            outline: 0;
            padding-top: 0.5em;
            padding-bottom: 0.5em;

            &:focus {
                outline: 0;
            }
        }
    }
    .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
        border-radius: 3px;
        bottom: 2em !important;
        background-color: transparent !important;
        color: #fff !important;
    }
    .js-focus-visible .vjs-menu li.vjs-menu-item:hover, 
    .vjs-menu li.vjs-menu-item:focus,
    .vjs-menu li.vjs-menu-item:hover {
        background-color: transparent !important;
        color: #fff !important;

        &.vjs-selected {
            background-color: transparent !important;
            color: #fff !important;
        }
    }

    // lang selection
    .vjs-menu-button-popup .vjs-menu {
        display: block !important;
        position: relative !important;
        width: auto;
        left: inherit;
        height: 100%;
        margin-bottom: 0;
        display: flex !important;
        align-items: center;
        border: none;
    }
    .vjs-menu-button-popup.vjs-audio-button {
        width: auto;
        display: flex;
        align-items: center;
        position: absolute;
        right: 40px;

        button {
            display: none;
        }
    }

    // custom exit-fullscreen-btn
    .vjs-exit-fullscreen {
        position: fixed !important;
        top: 0;
        display: none;
        height: 4em !important;
        .vjs-icon-placeholder {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 4em;
            text-align: center;
            &:before {
                content: '';
                position: relative;
                width: 17px;
                margin: 0 auto;
                display: block;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url('../../assets/icon-back.svg');
            }
        }
    }

    // fullscreen only rules
    &.vjs-fullscreen {
        .vjs-exit-fullscreen {
            display: block;
        }
    }

    // additional useless buttons
    // .vjs-control.vjs-subs-caps-button {
    //     display: none !important;
    // }

    .vjs-control.vjs-subs-caps-button {
        width: auto;
        display: none;

        .vjs-subs-caps-button {
            display: none;
        }

        .vjs-texttrack-settings {
            display: none !important;
        }

        .vjs-menu-item:nth-last-child(3) {
            display: none;
        }
    }

    .vjs-captions-menu-item,
    .vjs-subtitles-menu-item {
        .vjs-menu-item-text {
            display: flex;
            &:before {
                width: 20px;
                margin-right: 5px;
                font-family: VideoJS;
                content: "\F10D";
                font-size: 1.5em;
            }
            .vjs-icon-placeholder {
                display: none !important;
            }
        }
        &:before {
            display: none;
            @include for-smartphone {
                display: block;
                width: 20px;
                margin-right: 5px;
                font-family: VideoJS;
                content: "\F10D" !important;
                font-size: 1.5em;
            }
        }
    }
    .vjs-subs-caps-button .vjs-menu .vjs-menu-content {
        li {
            &:before {
                font-family: VideoJS;
                content: "\F10D";
                font-size: 1.5em;
                background-image: none;
            }
        }
    }
    .vjs-menu-button-popup.vjs-subs-caps-button .vjs-menu .vjs-menu-content {
        li {
            &:first-child {
                &:before {
                    font-family: VideoJS;
                    content: "\F10D";
                    font-size: 1.5em;
                    background-image: none;
                }
            }
        }
    }

}