@import "../../vars.scss";

.Intro {
    height: 100%;
    .Page {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .Intro-top {
        font-size: 1.3rem;
        padding: 2vw 0;
        animation: fadeIn 2000ms forwards;
        img {
            height: 15px;
            width: auto;
        }
        h4 {
            font-size: 1.8rem;
            letter-spacing: 1px;
        }
    }

    .Intro-center {
        display: block;
        width: 0;
        text-align: right;
        position: relative;
        transition: width 3s, opacity 500ms;

        &.hide {
            opacity: 0;
        }

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: $color-pink;
        }
        .Intro-dino {
            display: inline-block;
            width: 80px;
            height: 65px;
            background-image: url('../../assets/dinosaur.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .Intro-bottom {
        padding: 2vw 0 5px;
        display: block;
        text-align: center;
        
        @include for-mobile {
            display: none;
        }

        p {
            font-size: 1.15rem;
            font-weight: 300;
        }
    }

    .Intro-logo {
        position: absolute;
        left: 25%;
        right: 25%;
        top: 0;
        bottom: 0;
        background-image: url('../../assets/reportage-bizarre.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        transition: opacity 1500ms;

        &.active {
            opacity: 1;
        }

        h1 {
            display: none;
        }
    }

    .Intro-langs {
        position: absolute;
        left: 25%;
        right: 25%;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        color: $color-pink;
        transition: opacity 1500ms;
        &.active {
            opacity: 1;
            p {
                animation: fadeIn 900ms forwards;
            }
        }

        button {
            appearance: none;
            border: none;
            background-color: transparent;
            color: #fff;
            font-size: 16px;
            letter-spacing: 4px;
            margin: 0 20px;
            transition: color 400ms;
            cursor: pointer;

            &:hover {
                color: $color-pink;
            }
        }
    }

    .Intro-phrase {
        position: absolute;
        left: 25%;
        right: 25%;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 1500ms;
        visibility: hidden;

        @include for-mobile {
            left: 5%;
            right: 5%;
            text-align: left;
        }

        &.active {
            opacity: 1;
            visibility: visible;

            p {
                animation: fadeIn 1500ms forwards;
            }
             @for $i from 1 through 4 {
                p:nth-child(#{$i}) {
                    animation: fadeIn 1500ms forwards;
                    animation-delay: #{$i*1000}ms;
                }
            }
        }

        p {
            font-size: 1.6rem;
            letter-spacing: 1px;
            line-height: 1.7;
            opacity: 0;
            width: 100%;

            button {
                cursor: pointer;
                color: $color-pink;
                appearance: none;
                border: none;
                background: none;
                text-transform: uppercase;
                font-family: $font-base;
                letter-spacing: 4px;
                font-size: 1.7rem;
                margin: 0 auto;
                display: block;
            }
        }
    }
}