// colors
$color-pink: #FD557F;

// fonts
$font-base: 'VT323', monospace;

// Breakpoints
@mixin for-smartphone-small {
    @media (max-width: 320px) {
        @content;
    }
}
@mixin for-smartphone {
    @media (max-width: 599px) {
        @content;
    }
}
@mixin for-mobile {
    @media (max-width: 1024px) {
        @content;
    }
}
@mixin for-tablet {
    @media (min-device-width: 768px) and (max-device-width: 1024px) {
        @content;
    }
}
@mixin for-tablet-portrait {
    @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        @content;
    }
}
@mixin for-tablet-landscape {
    @media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        @content;
    }
}
@mixin for-ipadpro {
    @media only screen 
    and (device-width: 1366px) 
    and (device-height: 1024px) 
    and (-webkit-min-device-pixel-ratio: 1.5) {
        @content;
    }
}
@mixin from-laptop {
    @media (min-width: 1025px) {
        @content;
    }
}
@mixin for-laptop {
    @media (min-width: 1025px) and (max-width: 1440px) {
        @content;
    }
}
@mixin for-laptop-small {
    @media (min-width: 1025px) and (max-width: 1280px) {
        @content;
    }
}
@mixin for-desktop {
    @media (min-width: 1441px) {
        @content;
    }
}
@mixin for-large-desktop {
    @media (min-width: 2200px) {
        @content;
    }
}