@import '../../vars.scss';

.Header {
    position: fixed !important;
    height: 60px !important;
    left: 0;
    right: 50%;
    top: 0;
    z-index: 10;
    width: auto;
    display: flex;
    align-items: center;
    padding: 0 2%;
    animation: fadeIn 1200ms forwards;

    @include for-mobile {
        opacity: 1 !important;
    }

    &.Header--active {
        transition: opacity 400ms;
        animation: none;
        .Logo-img {
            opacity: 0;
        }
        .Logo-img--dino {
            opacity: 1;
            left: 0;
        }
    }

    .Logo {
        position: relative;
    }

    img {
        height: 40px;
        width: auto;
        transition: opacity 900ms, left 500ms;
    }

    .Logo-img--dino {
        opacity: 0;
        left: -10vw;
        position: absolute;
    }

    .Header-episode-indicator {
        margin-left: -100px;
        font-size: 2.2rem;
        font-weight: 300;
        opacity: 0;
        animation: fadeIn 800ms forwards;

        @include for-mobile {
            font-size: 1.7rem;
            display: flex;
            align-items: center;
        }

        em {
            font-style: normal;
            color: $color-pink;
            font-weight: 700;
            font-size: 2.5rem;
            @include for-mobile {
                font-size: 2rem;
                margin-right: 10px;
            }
        }
    }
}