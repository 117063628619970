@import "../../vars.scss";

.Home {
    .Page {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        position: relative;
    }

    .Home-episode-indicator {
        position: absolute;
        left: 0;
        right: 0;
        top: 4vw;
        text-align: center;
        font-size: 2.2rem;
        font-weight: 300;
        opacity: 0;
        animation: fadeIn 800ms forwards;

        em {
            font-style: normal;
            color: $color-pink;
            font-weight: 700;
            font-size: 3rem;
        }
    }
    .Home-episode-viewed-indicator {
        display: block;
        width: 13vw;
        height: 2px;
        margin: 10px auto;
        background-color: rgba(255,255,255,0.7);
        position: relative;

        span {
            font-size: 12px;
        }

        .Home-episode-viewed-indicator-bar {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            height: 2px;
            background-color: $color-pink;
        }
    }

    .Home-episode-background {
        display: block;
        width: 100%;
        position: fixed;
        z-index: -1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        animation: fadeInPartial 800ms forwards;
    }

    .Home-player {
        position: absolute;
        z-index: 8;
        left: 0;
        right: 0;
        top:0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
    }
    .Home-player-close {
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 15;
        cursor: pointer;
        width: 25px;
        height: 25px;
        background-image: url('../../assets/icon-close.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: opacity 400ms;

        @include for-mobile {
            opacity: 1 !important;
        }

        &:hover {
            background-image: url('../../assets/icon-close-w.svg');
        }

        span {
            display: none;
        }
    }
}