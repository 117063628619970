@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

@keyframes pulseOpacity {
	0% {
		opacity: 0.2;
    }
    50% {
        opacity: 0.8;
    }
	100% {
		opacity: 0.2;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
  }
	100% {
		opacity: 1;
	}
}
@keyframes fadeInPartial {
	0% {
		opacity: 0;
  }
	100% {
		opacity: 0.4;
	}
}