@import '../../vars.scss';

.AudioPlayer-button {
    appearance: none;
    background-color: transparent;
    border: none;
    position: absolute;
    right: 15px;
    bottom: 15px;
    z-index: 10;
    width: 30px;
    height: 30px;
    background-image: url('../../assets/icon-audio-on.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    animation: pulseOpacity 1500ms infinite;
    cursor: pointer;
    
    &.AudioPlayer-button--active {
        background-image: url('../../assets/icon-audio-off.svg');
        animation: none;
    }

    span {
        display: none;
    }

    &:focus {
        outline: none;
    }
}